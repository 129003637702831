import React from 'react';
import {
  DAILY_IN_OUT_CATEGORY_TYPE,
  DAILY_IN_OUT_TYPE,
} from '../../../../constant/DailyInOut/elements';
import {
  STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_16PX,
  STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_8PX,
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
} from '../../../../style/DailyInOut/StyledAddEditModalWrapper/style';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete';
import CommonSelect from '../../../Common/Controller/Select';
import CommonTextInput from '../../../Common/Controller/Text';

const Set2 = ({
  selectedYard,
  flipModalController,
  inventoryContainerWithChassisOption,
  inventoryContainerWithChassisList,
  inventoryChassisWithContainerOption,
  inventoryChassisWithContainerList,
}) => {
  return (
    <div className="w-[412px] h-[508px] pl-[28px] bg-[#F8F8F8] flex flex-col items-center">
      <div className="pt-[17px] text-[#DB5C00] text-[11px] font-bold text-center">
        SET 2
      </div>
      <div className="pt-[8px] mb-[18px] text-[11px] font-bold text-center">
        Container
      </div>
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_8PX}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonAutoComplete
              label="CONT #"
              inputName="cont_no_2"
              width="w-[108px]"
              option={inventoryContainerWithChassisOption}
              disabled={!selectedYard}
              onChangeHandler={({ onChange, selectProps }) => {
                onChange(selectProps?.value);
                inventoryContainerWithChassisList?.forEach(item => {
                  if (selectProps?.value === '') {
                    flipModalController.setValue('cont_wo_div_2', '');
                    flipModalController.setValue('cont_category_2', '');
                    flipModalController.setValue('cont_status_2', '');
                    flipModalController.setValue('cont_size_2', '');
                    flipModalController.setValue('cont_type_2', '');
                    flipModalController.setValue('cont_ssl_2', '');
                    flipModalController.setValue('cont_seal_2', '');
                    flipModalController.setValue('cont_remarks_2', '');
                    flipModalController.setValue('ch_no_2', '');
                    flipModalController.setValue('ch_pool_2', '');
                    flipModalController.setValue('ch_type_2', '');
                    flipModalController.setValue('ch_plate_no_2', '');
                    flipModalController.setValue('ch_remarks_2', '');
                  }
                  if (item.cont_no === selectProps?.value) {
                    flipModalController.setValue(
                      'cont_wo_div_2',
                      item.cont_wo_div || '',
                    );
                    flipModalController.setValue(
                      'cont_category_2',
                      DAILY_IN_OUT_CATEGORY_TYPE[item.cont_category] || '',
                    );
                    flipModalController.setValue(
                      'cont_status_2',
                      DAILY_IN_OUT_TYPE[item.cont_status] || '',
                    );
                    flipModalController.setValue(
                      'cont_size_2',
                      item.cont_size || '',
                    );
                    flipModalController.setValue(
                      'cont_type_2',
                      item.cont_type || '',
                    );
                    flipModalController.setValue(
                      'cont_ssl_2',
                      item.cont_ssl || '',
                    );
                    flipModalController.setValue(
                      'cont_seal_2',
                      item.cont_seal || '',
                    );
                    flipModalController.setValue(
                      'cont_remarks_2',
                      item.cont_remarks || '',
                    );
                    flipModalController.setValue('ch_no_2', item.ch_no || '');
                    flipModalController.setValue(
                      'ch_pool_2',
                      item.ch_pool || '',
                    );
                    flipModalController.setValue(
                      'ch_type_2',
                      item.ch_type || '',
                    );
                    flipModalController.setValue(
                      'ch_plate_no_2',
                      item.ch_plate_no || '',
                    );
                    flipModalController.setValue(
                      'ch_remarks_2',
                      item.ch_remarks || '',
                    );
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="h-[9px]" />
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_16PX}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="WO DIV"
              inputName="cont_wo_div_2"
              width="w-[108px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="CATEGORY"
              inputName="cont_category_2"
              width="w-[108px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="STATUS"
              inputName="cont_status_2"
              width="w-[108px]"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="h-[9px]" />
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_16PX}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="SZ"
              inputName="cont_size_2"
              width="w-[108px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="TYPE"
              inputName="cont_type_2"
              width="w-[108px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="SSL"
              inputName="cont_ssl_2"
              width="w-[108px]"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="h-[9px]" />
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_16PX}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="SEAL #"
              inputName="cont_seal_2"
              width="w-[108px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="REMARKS"
              inputName="cont_remarks_2"
              width="w-[232px]"
              disabled={!selectedYard}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="ml-[-28px] h-[1.5px] w-[356px] bg-[#D9D9D9] mt-[6.5px]" />
      <div className="mt-[17px] mb-[18px] text-[11px] font-bold text-center">
        Chassis
      </div>
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_16PX}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonAutoComplete
              label="CHASSIS #"
              inputName="ch_no_2"
              width="w-[108px]"
              option={inventoryChassisWithContainerOption}
              disabled={!selectedYard}
              onChangeHandler={({ onChange, selectProps }) => {
                onChange(selectProps?.value);
                inventoryChassisWithContainerList?.forEach(item => {
                  if (selectProps?.value === '') {
                    flipModalController.setValue('cont_no_2', '');
                    flipModalController.setValue('cont_wo_div_2', '');
                    flipModalController.setValue('cont_category_2', '');
                    flipModalController.setValue('cont_status_2', '');
                    flipModalController.setValue('cont_size_2', '');
                    flipModalController.setValue('cont_type_2', '');
                    flipModalController.setValue('cont_ssl_2', '');
                    flipModalController.setValue('cont_seal_2', '');
                    flipModalController.setValue('cont_remarks_2', '');
                    flipModalController.setValue('ch_pool_2', '');
                    flipModalController.setValue('ch_type_2', '');
                    flipModalController.setValue('ch_plate_no_2', '');
                    flipModalController.setValue('ch_remarks_2', '');
                  }

                  if (item.ch_no === selectProps?.value) {
                    flipModalController.setValue('cont_no_2', item.cont_no);
                    flipModalController.setValue(
                      'cont_wo_div_2',
                      item.cont_wo_div || '',
                    );
                    flipModalController.setValue(
                      'cont_category_2',
                      DAILY_IN_OUT_CATEGORY_TYPE[item.cont_category] || '',
                    );
                    flipModalController.setValue(
                      'cont_status_2',
                      DAILY_IN_OUT_TYPE[item.cont_status] || '',
                    );
                    flipModalController.setValue(
                      'cont_size_2',
                      item.cont_size || '',
                    );
                    flipModalController.setValue(
                      'cont_type_2',
                      item.cont_type || '',
                    );
                    flipModalController.setValue(
                      'cont_ssl_2',
                      item.cont_ssl || '',
                    );
                    flipModalController.setValue(
                      'cont_seal_2',
                      item.cont_seal || '',
                    );
                    flipModalController.setValue(
                      'cont_remarks_2',
                      item.cont_remarks || '',
                    );
                    flipModalController.setValue(
                      'ch_pool_2',
                      item.ch_pool || '',
                    );
                    flipModalController.setValue(
                      'ch_type_2',
                      item.ch_type || '',
                    );
                    flipModalController.setValue(
                      'ch_plate_no_2',
                      item.ch_plate_no || '',
                    );
                    flipModalController.setValue(
                      'ch_remarks_2',
                      item.ch_remarks || '',
                    );
                  }
                });
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="POOL"
              inputName="ch_pool_2"
              width="w-[108px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="TYPE"
              inputName="ch_type_2"
              width="w-[108px]"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="h-[9px]" />
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_16PX}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="PLATE #"
              inputName="ch_plate_no_2"
              width="w-[108px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="REMARKS"
              inputName="ch_remarks_2"
              width="w-[232px]"
              disabled={!selectedYard}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Set2;
