import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';

const toggleFilterSelection = (groups, groupValue, itemValue) => {
  return groups.map(group => {
    if (group.title.value === groupValue) {
      return {
        ...group,
        items: group.items.map(item => {
          if (item.value === itemValue) {
            return { ...item, isSelected: !item.isSelected };
          }
          return item;
        }),
      };
    }
    return group;
  });
};

const generateInitFilter = ({
  currDivYardList,
  setFilterState,
  storageName,
}) => {
  const userID = localStorage?.getItem('userId');

  const existFilter = localStorage?.getItem(storageName);
  const userFilter = existFilter ? JSON.parse(existFilter)?.[userID] : null;

  const initFilter = [
    {
      title: {
        icon: PATH_COMMON_BUTTON_ICON?.filterYard?.default,
        label: 'YARD',
        value: 'yard',
      },
      items: [...currDivYardList]?.map(yard => ({
        label: yard?.name,
        value: yard?.name,
        isSelected:
          userFilter?.yard?.[yard?.name] !== undefined
            ? userFilter?.yard?.[yard?.name]
            : true,
        onClick: () => {
          setFilterState(prev =>
            toggleFilterSelection(prev, 'yard', yard?.name),
          );
        },
      })),
    },
  ];

  return initFilter;
};

export default generateInitFilter;
