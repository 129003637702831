import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { COMMON_STATE } from '../../../../constant/Common/Option/State';
import {
  STYLE_CONTENT_WRAPPER,
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
} from '../../../../style/DailyInOut/StyledAddEditModalWrapper/style';
import {
  createMandatorySelectOption,
  createOptionalSelectOption,
} from '../../../../util/Common/Option/Generator/selectOptionCreator';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete';
import CommonSelect from '../../../Common/Controller/Select';
import CommonTextInput from '../../../Common/Controller/Text';

const Truck = ({
  selectedDailyInOut,
  isAdd,
  isOut,
  inventoryTruckList,
  selectedYard,
  isLoadingInventoryTruckList,
  existingData,
}) => {
  const { setValue } = useFormContext();
  const [selectedItem, setSelectedItem] = useState({});

  const resetFieldsData = () => {
    setValue('truck_no', '');
    setValue('truck_dot_no', '');
    setValue('truck_plate_no', '');
    setValue('truck_state', '');
    setValue('truck_company', '');
    setValue('truck_color', '');
    setValue('truck_yard_location', '');
    setValue('truck_remarks', '');
  };

  useEffect(() => {
    setSelectedItem({});
  }, [isOut]);

  return (
    <div className="w-full h-[203px] bg-[#f9f9f9] mt-[10px] pt-[20px] pl-[36px] pr-[36px] flex flex-col gap-[10px]">
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold">
        Truck
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            {isOut ? (
              <CommonAutoComplete
                label="TRUCK #"
                inputName="truck_no"
                width="w-[140px]"
                disabled={!isAdd || (isOut && !selectedYard)}
                defaultValue={
                  !isAdd ? selectedDailyInOut?.truck_no : existingData?.truck_no
                }
                option={createMandatorySelectOption({
                  items: inventoryTruckList,
                  labelKey: 'truck_no',
                  valueKey: 'truck_no',
                })}
                onChangeHandler={({ e, onChange, selectProps }) => {
                  if (selectProps?.value !== selectedItem?.truck_no)
                    resetFieldsData();
                  onChange?.(selectProps?.value);
                  const selectedValue = inventoryTruckList.find(
                    item => item.truck_no === selectProps.value,
                  );
                  setSelectedItem(selectedValue);
                  setValue('truck_state', selectedValue?.state);
                }}
                isLoading={isLoadingInventoryTruckList}
              />
            ) : (
              <CommonTextInput
                label="TRUCK #"
                inputName="truck_no"
                width="w-[140px]"
                disabled={!isAdd || isOut}
                defaultValue={
                  !isAdd ? selectedDailyInOut?.truck_no : existingData?.truck_no
                }
                maxLength={10}
                onChangeHandler={({ e, onChange }) => {
                  onChange(e?.target?.value?.toUpperCase());
                }}
              />
            )}
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="DOT #"
              inputName="truck_dot_no"
              width="w-[140px]"
              disabled={!isAdd || isOut}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.truck_dot_no : '') ||
                (isOut ? selectedItem?.dot_no : existingData?.truck_dot_no)
              }
              maxLength={10}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="PLATE #"
              inputName="truck_plate_no"
              width="w-[140px]"
              disabled={!isAdd || isOut}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.truck_plate_no : '') ||
                (isOut ? selectedItem?.plate_no : existingData?.truck_plate_no)
              }
              maxLength={10}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="STATE"
              inputName="truck_state"
              width="w-[140px]"
              disabled={!isAdd || isOut}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.truck_state : '') ||
                (isOut ? selectedItem?.state : existingData?.truck_state)
              }
              option={createOptionalSelectOption({
                items: COMMON_STATE,
                labelKey: 'label',
                valueKey: 'value',
              })}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="COMPANY"
              inputName="truck_company"
              width="w-[140px]"
              disabled={!isAdd || isOut}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.truck_company : '') ||
                (isOut ? selectedItem?.company : existingData?.truck_company)
              }
              maxLength={20}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="COLOR"
              inputName="truck_color"
              width="w-[140px]"
              disabled={!isAdd || isOut}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.truck_color : '') ||
                (isOut ? selectedItem?.color : existingData?.truck_color)
              }
              maxLength={10}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="LOCATION"
              inputName="truck_yard_location"
              width="w-[140px]"
              disabled={!isAdd || isOut}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.truck_yard_location : '') ||
                (isOut
                  ? selectedItem?.yard_location
                  : existingData?.truck_yard_location)
              }
              maxLength={5}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="REMARKS"
              inputName="truck_remarks"
              width="w-[140px]"
              disabled={!isAdd || isOut}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.truck_remarks : '') ||
                (isOut ? selectedItem?.remarks : existingData?.truck_remarks)
              }
              maxLength={50}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Truck;
