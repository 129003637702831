import styled from 'styled-components';

const StyledFilterModalWrapper = styled.div`
  position: absolute;
  top: 150%;
  right: 0%;
  transform: translate(0%, 0%);

  width: fit-content;
  height: fit-content;

  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4.04px 4.04px 0px rgba(0, 0, 0, 0.25);

  z-index: 100;
`;

export default StyledFilterModalWrapper;
