import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createObjOption } from './optionCreator';
import generateInitFilter from './generateInitFilter';

const generateSelectOptions = ({ setFilterState }) => {
  const currDiv = useSelector(state => state?.common?.currDiv);
  const poolList = useSelector(state => state.common.poolList);
  const sizeList = useSelector(state => state.common.sizeList);
  const sslList = useSelector(state => state.common.sslList);
  const typeList = useSelector(state => state.common.typeList);
  const chassisTypeList = useSelector(state => state.common.chassisTypeList);
  const ymsYard = useSelector(state => state.common.ymsYard);

  /** Set initial selected yard */
  useEffect(() => {
    const currDivYardList = ymsYard?.filter(yard => yard?.div === currDiv);

    if (currDivYardList && currDivYardList?.length > 0) {
      const newInitFilter = generateInitFilter({
        currDivYardList,
        setFilterState,
        storageName: 'inventoryFilter',
      });

      setFilterState(newInitFilter);
    }
  }, [ymsYard, currDiv]);

  const selectOptions = {
    poolOption: createObjOption(poolList, 'pool'),
    sizeOption: createObjOption(sizeList, 'size'),
    sslOption: createObjOption(sslList, 'ssl'),
    typeOption: createObjOption(typeList, 'type'),
    chassisTypeOption: createObjOption(chassisTypeList, 'type'),
    yardOption: createObjOption(ymsYard, 'name'),
  };

  return { selectOptions };
};

export default generateSelectOptions;
