import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';

const clickGridCell = ({
  setSpecificSeq,
  setModalOpen,
  setEditMode,
  ...props
}) => {
  if (props?.gridParam?.column?.colId !== 'remarks') {
    setEditMode(EDIT_MODE?.edit);
    setSpecificSeq(props?.gridParam?.data?.seq);
    setModalOpen(true);
  }
};

export default clickGridCell;
