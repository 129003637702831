import { useQuery } from '@tanstack/react-query';
import getInventoryContainerWithChassisList from '../../../service/DailyInOut/Get/getInventoryContainerWithChassisList';
import dailyInOutKeys from '../keys';

const useGetInventoryContainerWithChassisList = ({ div, yard }) => {
  const {
    isLoading: isLoadingInventoryContainerWithChassisList,
    data,
    isRefetching: isRefetchingInventoryContainerWithChassisList,
    isError: isErrorInventoryContainerWithChassisList,
  } = useQuery({
    queryKey: dailyInOutKeys?.containerWithChassisList({ div, yard }),
    queryFn: () => getInventoryContainerWithChassisList({ div, yard }),
    refetchOnWindowFocus: false,
    enabled: !!yard,
  });

  return {
    isLoadingInventoryContainerWithChassisList,
    inventoryContainerWithChassisList: data?.data,
    isRefetchingInventoryContainerWithChassisList,
    isErrorInventoryContainerWithChassisList,
  };
};

export default useGetInventoryContainerWithChassisList;
