import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  DAILY_IN_OUT_CATEGORY_OPTION,
  DAILY_IN_OUT_OPTION,
  FLIP_OPTION,
} from '../../../../constant/DailyInOut/elements';
import {
  STYLE_BASIC_INFO_CONTENT_WRAPPER,
  STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER,
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
} from '../../../../style/DailyInOut/StyledAddEditModalWrapper/style';
import { createOptionalSelectOption } from '../../../../util/Common/Option/Generator/selectOptionCreator';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete/index';
import CommonDate from '../../../Common/Controller/Date';
import CommonRadioInput from '../../../Common/Controller/Radio/index';
import CommonSelect from '../../../Common/Controller/Select';
import CommonTextInput from '../../../Common/Controller/Text';
import CommonTime from '../../../Common/Controller/Time/index';

const BasicInfo = ({
  selectedDailyInOut,
  isAdd,
  divList,
  yardOption,
  driverOption,
  isOut,
  setIsOut,
  div,
  setSelectedYard,
  inputCategory,
  setInputCategory,
  existingData,
  hasEditablePermission,
}) => {
  const { setValue, getValues } = useFormContext();

  const resetFields = () => {
    setValue('truck_no', '');
    setValue('truck_dot_no', '');
    setValue('truck_plate_no', '');
    setValue('truck_state', '');
    setValue('truck_company', '');
    setValue('truck_color', '');
    setValue('truck_yard_location', '');
    setValue('truck_remarks', '');
    setValue('cont_no', '');
    setValue('cont_size', '');
    setValue('cont_type', '');
    setValue('cont_ssl', '');
    setValue('cont_seal', '');
    setValue('cont_color', '');
    setValue('cont_yard_location', '');
    setValue('cont_remarks', '');
    setValue('trailer_no', '');
    setValue('trailer_size', '');
    setValue('trailer_type', '');
    setValue('trailer_seal', '');
    setValue('trailer_color', '');
    setValue('trailer_yard_location', '');
    setValue('trailer_remarks', '');
    setValue('ch_no', '');
    setValue('ch_pool', '');
    setValue('ch_type', '');
    setValue('ch_plate_no', '');
    setValue('ch_state', '');
    setValue('ch_bare', '');
    setValue('ch_color', '');
    setValue('ch_yard_location', '');
    setValue('ch_remarks', '');
  };

  useEffect(() => {
    if (getValues('in_out_type')?.includes('DTF')) {
      const inOutType =
        getValues('in_out_type') === 'DTF001' ? 'DTO001' : 'DTO002';
      setValue('in_out_type', inOutType);
      setIsOut(true);
    }
  }, [isAdd]);

  return (
    <div className="w-full h-[313px] bg-[#f9f9f9] pt-[20px] pl-[36px] pr-[36px] flex flex-col gap-[5px]">
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold mb-[15px]">
        Basic Info
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label="DATE"
              inputName="created_date"
              width="w-[170px]"
              defaultValue={!isAdd && selectedDailyInOut?.created_date}
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTime
              label="TIME"
              inputName="created_time"
              width="w-[180px]"
              defaultValue={
                !isAdd &&
                selectedDailyInOut?.created_date &&
                selectedDailyInOut?.created_time &&
                dayjs(
                  `${selectedDailyInOut?.created_date} ${selectedDailyInOut?.created_time}`,
                )?.isValid()
                  ? dayjs(
                      `${selectedDailyInOut?.created_date} ${selectedDailyInOut?.created_time}`,
                    )
                  : null
              }
              disabled
            />
          </div>
        </div>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="CREATED BY"
              inputName="created_by"
              width="w-[106px]"
              defaultValue={(!isAdd && selectedDailyInOut?.created_by) || ''}
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label=""
              inputName="created_date"
              width="w-[106px]"
              defaultValue={!isAdd && selectedDailyInOut?.created_date}
              disabled
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="DIVISION"
              inputName="division"
              width="w-[170px]"
              option={divList}
              defaultValue={
                (!isAdd && selectedDailyInOut?.div) || existingData?.div || div
              }
              disabled
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="YARD"
              inputName="yard"
              width="w-[180px]"
              option={yardOption}
              defaultValue={
                !isAdd ? selectedDailyInOut?.yard : existingData?.yard
              }
              disabled={!hasEditablePermission || !isAdd}
              required
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
                setSelectedYard(e?.target?.value);
                if (isOut) {
                  setInputCategory('');
                  resetFields();
                }
              }}
            />
          </div>
        </div>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="UPDATED BY"
              inputName="updated_by"
              width="w-[106px]"
              defaultValue={(!isAdd && selectedDailyInOut?.updated_by) || ''}
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label=""
              inputName="updated_date"
              width="w-[106px]"
              defaultValue={!isAdd && selectedDailyInOut?.updated_date}
              disabled
            />
          </div>
        </div>
      </div>
      {/* Third row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="WO DIV"
              inputName="wo_div"
              width="w-[170px]"
              option={divList}
              defaultValue={
                !isAdd ? selectedDailyInOut?.wo_div : existingData?.wo_div
              }
              disabled={!hasEditablePermission}
              required={getValues('cont_no') || getValues('trailer_no')}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="CATEGORY"
              inputName="category"
              width="w-[180px]"
              option={createOptionalSelectOption({
                items: DAILY_IN_OUT_CATEGORY_OPTION,
              })}
              defaultValue={
                !isAdd ? selectedDailyInOut?.category : existingData?.category
              }
              disabled={!hasEditablePermission}
              required={getValues('cont_no') || getValues('trailer_no')}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
        <div className={STYLE_BASIC_INFO_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonAutoComplete
              label="DRIVER ID"
              inputName="driver"
              width="w-[222px]"
              option={driverOption}
              disabled={!hasEditablePermission}
              defaultValue={
                !isAdd ? selectedDailyInOut?.driver : existingData?.driver
              }
              onChangeHandler={({ e, onChange, selectProps }) => {
                onChange?.(selectProps?.value);
              }}
            />
          </div>
        </div>
      </div>
      {/* Fourth row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_CONTENT_WRAPPER}>
          <div
            className={`${hasEditablePermission ? 'bg-white' : 'bg-gray'} w-[365px] h-[30px] rounded-[5px]`}
          >
            <div className="mt-[-17px]">
              <CommonRadioInput
                label="IN/OUT"
                inputName="in_out_type"
                borderVisible
                width="w-[365px]"
                options={
                  selectedDailyInOut?.in_out_type?.includes('DTF') && !isAdd
                    ? FLIP_OPTION
                    : DAILY_IN_OUT_OPTION
                }
                defaultValue={
                  (!isAdd && selectedDailyInOut?.in_out_type) ||
                  existingData?.in_out_type ||
                  'DTI001'
                }
                disabled={
                  (selectedDailyInOut?.in_out_type?.includes('DTF') &&
                    !isAdd && [true, true]) ||
                  (!hasEditablePermission && [true, true, true, true]) ||
                  (!isAdd &&
                    (selectedDailyInOut?.in_out_type === 'DTO001' ||
                    selectedDailyInOut?.in_out_type === 'DTO002'
                      ? [true, false, true, false]
                      : [false, true, false, true])) ||
                  (existingData?.in_out_type &&
                    (existingData?.in_out_type === 'DTO001' ||
                    existingData?.in_out_type === 'DTO002'
                      ? [false, true, false, true]
                      : [true, false, true, false]))
                }
                required
                onChangeHandler={({ e, onChange }) => {
                  onChange?.(e?.target?.value);
                  if (
                    (!isOut && e?.target?.value.includes('DTO')) ||
                    (!!isOut && e?.target?.value.includes('DTI'))
                  ) {
                    setInputCategory('');
                    resetFields();
                  }
                  if (
                    e?.target?.value === 'DTO001' ||
                    e?.target?.value === 'DTO002'
                  ) {
                    setIsOut(true);
                  } else {
                    setIsOut(false);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={STYLE_BASIC_INFO_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="REMARKS"
              inputName="remarks"
              width="w-[222px]"
              maxLength={50}
              disabled={!hasEditablePermission}
              defaultValue={
                !isAdd ? selectedDailyInOut?.remarks : existingData?.remarks
              }
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
