import { useQuery } from '@tanstack/react-query';
import getInventoryChassisWithContainerList from '../../../service/DailyInOut/Get/getInventoryChassisWithContainerList';
import dailyInOutKeys from '../keys';

const useGetInventoryChassisWithContainerList = ({ div, yard }) => {
  const {
    isLoading: isLoadingInventoryChassisWithContainerList,
    data,
    isRefetching: isRefetchingInventoryChassisWithContainerList,
    isError: isErrorInventoryChassisWithContainerList,
  } = useQuery({
    queryKey: dailyInOutKeys?.chassisWithContainerList({ div, yard }),
    queryFn: () => getInventoryChassisWithContainerList({ div, yard }),
    refetchOnWindowFocus: false,
    enabled: !!yard,
  });

  return {
    isLoadingInventoryChassisWithContainerList,
    inventoryChassisWithContainerList: data?.data,
    isRefetchingInventoryChassisWithContainerList,
    isErrorInventoryChassisWithContainerList,
  };
};

export default useGetInventoryChassisWithContainerList;
