import React from 'react';
import CommonButton from '../../Common/Controller/Button';
import FilterNavigator from '../FilterNavigator';
import StyledUtilityBarContainer from '../../../style/Inventory/StyledUtilityBarContainer';
import exportToExcel from '../../../util/Common/Datagrid/Handler/exportToExcel';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';
import { PRESET_GREEN } from '../../../constant/Common/Style/preset';
import RefreshButton from '../../Common/RefreshButton';
import CommonFilter from '../../Common/Filter';
import saveFilter from '../../../util/Common/Filter/saveFilter';

const UtilityBar = ({
  isFilterOpen,
  setIsFilterOpen,
  gridRef,
  fileName,
  queryKeyList,
  filterState,
  ...props
}) => {
  return (
    <StyledUtilityBarContainer>
      <FilterNavigator {...props} />
      <div className="utility-button-field">
        <RefreshButton queryKeyList={queryKeyList} />
        <CommonFilter
          open={isFilterOpen}
          setOpen={setIsFilterOpen}
          filters={filterState}
          onSave={() =>
            saveFilter({
              storageName: 'inventoryFilter',
              filters: filterState,
              setOpen: setIsFilterOpen,
              filterKeyList: ['yard'],
            })
          }
        />
        <CommonButton
          styles={PRESET_GREEN}
          icon={PATH_COMMON_BUTTON_ICON.excelGreen}
          onClick={() => exportToExcel({ gridRef, fileName })}
        >
          EXCEL
        </CommonButton>
      </div>
    </StyledUtilityBarContainer>
  );
};

export default UtilityBar;
