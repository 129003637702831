const clearLocalStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('permission');
  localStorage.removeItem('allDiv');
  localStorage.removeItem('defaultDiv');
};

export default clearLocalStorage;
