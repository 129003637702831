import styled from 'styled-components';

const StyledFilterItemWrapper = styled.div`
  height: 20px;
  width: ${props => props?.$style?.width || `fit-content`};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;

  border-radius: 50px;
  background-color: ${props => {
    if (props?.isSelected)
      return props?.$style?.selectedBackgroundColor || `#A6A6A6`;
    return props?.$style?.backgroundColor || `#f7f7f7`;
  }};

  font-size: 11px;
  font-weight: 400;
  color: ${props => {
    if (props?.isSelected) return props?.$style?.selectedColor || `black`;
    return props?.$style?.color || `black`;
  }};
  line-height: 10px;

  cursor: pointer;

  &:hover {
    transition-duration: 300ms;
    background-color: ${props => {
      // Change color when isSelected is not true
      if (props?.isSelected)
        return props?.$style?.selectedBackgroundColor || `#A6A6A6`;
      return props?.$style?.hoverBackgroundColor || `#d6d6d6`;
    }};
    color: ${props => {
      // Change color when isSelected is not true
      if (props?.isSelected) return props?.$style?.selectedColor || `black`;
      return props?.$style?.hoverColor || `black`;
    }};
  }
`;

export default StyledFilterItemWrapper;
