import React from 'react';
import FilterButton from './Button';
import FilterModal from './Modal';
import useControlCommonFilterOpen from '../../../hook/Common/Filter/useControlCommonFilterOpen';

const CommonFilter = ({ filters, onSave, open, setOpen, ...props }) => {
  const { buttonRef, modalRef } = useControlCommonFilterOpen({ open, setOpen });

  /** Rendering */
  return (
    <div className="relative">
      <FilterButton ref={buttonRef} setOpen={setOpen} />

      {/* Display modal only when open is true */}
      {open && (
        <FilterModal
          ref={modalRef}
          filters={filters}
          setOpen={setOpen}
          onSave={onSave}
          {...props}
        />
      )}
    </div>
  );
};

export default CommonFilter;
