import React from 'react';
import {
  STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_2PX,
  STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_6PX,
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
} from '../../../../style/DailyInOut/StyledAddEditModalWrapper/style';
import CommonDate from '../../../Common/Controller/Date';
import CommonSelect from '../../../Common/Controller/Select';
import CommonTextInput from '../../../Common/Controller/Text';
import CommonTime from '../../../Common/Controller/Time';

const BasicInfo = ({ yardOption, currDiv, selectedYard, setSelectedYard }) => {
  return (
    <div className="w-[834px] h-[184px] pl-[15px] bg-[#F8F8F8] flex flex-col">
      <div className="pt-[14px] mb-[20px] text-[#264B9F] text-[14px] font-bold">
        Basic Info
      </div>
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_6PX}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label="DATE"
              inputName="created_date"
              width="w-[140px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTime
              label="TIME"
              inputName="created_time"
              width="w-[140px]"
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="DIVISION"
              inputName="div"
              width="w-[140px]"
              defaultValue={currDiv}
              disabled
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="YARD"
              inputName="yard"
              width="w-[140px]"
              option={yardOption}
              required
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
                setSelectedYard(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_2PX}>
            <div className={STYLE_ITEM_WRAPPER}>
              <CommonTextInput
                label="CREATED BY"
                inputName="created_by"
                width="w-[110px]"
                disabled
              />
            </div>
            <div className={STYLE_ITEM_WRAPPER}>
              <CommonDate
                label=""
                inputName="created_date"
                width="w-[110px]"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[13px]" />
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ITEM_WRAPPER}>
          <CommonTextInput
            label="REMARKS"
            inputName="remarks"
            width="w-[806px]"
            disabled={!selectedYard}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
