const convertFilterToQuery = ({ filterState }) => {
  const allFilter = filterState?.reduce((acc, filter) => {
    acc[filter.title.value] = filter?.items?.reduce((itemAcc, item) => {
      itemAcc[item.value] = item?.isSelected;
      return itemAcc;
    }, {});
    return acc;
  }, {});

  return allFilter;
};

export default convertFilterToQuery;
