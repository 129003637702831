import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { ERROR_INVALID_CONTAINER_NUMBER } from '../../../constant/Common/Error/message';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';
import {
  PRESET_GREEN,
  PRESET_ORANGE,
  PRESET_PRIMARY,
  PRESET_RED,
} from '../../../constant/Common/Style/preset';
import updateButtonDisabled from '../../../util/Common/Controller/Handler/updateButtonDisabled';
import checkContainerNumberConstraint from '../../../util/Common/Validator/Handler/checkContainerNumberConstraint';
import CommonButton from '../../Common/Controller/Button';
import CommonMessageModalFrame from '../../Common/Modal/Message/Frame';
import BasicInfo from './BasicInfo';
import Chassis from './Chassis';
import Container from './Container';
import Trailer from './Trailer';
import Truck from './Truck';

const AddEditModal = ({
  isAddEditModalOpen,
  setIsAddEditModalOpen,
  isAdd,
  setIsAdd,
  selectedDailyInOut,
  divList,
  yardOption,
  driverOption,
  typeOption,
  poolOption,
  sizeOption,
  sslOption,
  inventoryTruckList,
  inventoryContainerList,
  inventoryTrailerList,
  inventoryChassisList,
  postDailyInOutMutation,
  patchDailyInOutMutation,
  div,
  selectedYard,
  setSelectedYard,
  addEditModalController,
  setMessage,
  setMessageStatus,
  setIsMessageOpen,
  chassisTypeOption,
  isLoadingInventoryTruckList,
  isLoadingInventoryContainerList,
  isLoadingInventoryTrailerList,
  isLoadingInventoryChassisList,
}) => {
  const {
    reset,
    setValue,
    formState: { isValid },
  } = addEditModalController;
  const [isOut, setIsOut] = useState(false);
  const [inputCategory, setInputCategory] = useState('');
  const [isBare, setIsBare] = useState(false);
  const isOutEditModal = selectedDailyInOut?.in_out_type?.includes('DTO');
  const [existingData, setExistingData] = useState({});
  const hasEditablePermission = localStorage
    .getItem('permission')
    ?.includes('in_out_daily_rw');

  useEffect(() => {
    if (isAddEditModalOpen && !isAdd) {
      setValue('in_out_type', selectedDailyInOut?.in_out_type);
    }
    setIsOut(false);
    setInputCategory('');
    setSelectedYard(null);
    setExistingData({});
  }, [isAddEditModalOpen]);

  const addDailyInOut = ({ data, buttonRef }) => {
    const result = addEditModalController.getValues();
    if (
      result?.cont_no &&
      !checkContainerNumberConstraint({
        containerNo: result.cont_no,
        containerSize: result.cont_size,
        containerType: result.cont_type,
      })
    ) {
      if (buttonRef) updateButtonDisabled({ ref: buttonRef, disabled: false });
      setMessageStatus(ALERT_MODAL_STATUS.ERROR);
      setMessage(ERROR_INVALID_CONTAINER_NUMBER);
      setIsMessageOpen(true);

      return;
    }
    postDailyInOutMutation({ data: result, div, buttonRef });
  };

  const editDailyInOut = ({ submitData, buttonRef }) => {
    patchDailyInOutMutation({
      seq: selectedDailyInOut?.seq,
      data: {
        wo_div: submitData?.wo_div,
        category: submitData?.category,
        remarks: submitData?.remarks,
        yard: submitData?.yard,
        driver: submitData?.driver,
        in_out_type: submitData?.in_out_type,
      },
      buttonRef,
    });
  };

  const changeInOut = () => {
    setIsAdd(true);
    const inOut = selectedDailyInOut?.in_out_type.includes('DTO')
      ? selectedDailyInOut?.in_out_type.replace('DTO', 'DTI')
      : selectedDailyInOut?.in_out_type.replace('DTI', 'DTO');
    if (selectedDailyInOut?.ch_no) {
      setInputCategory('chassis');
    } else if (selectedDailyInOut?.trailer_no) {
      setInputCategory('trailer');
    } else if (selectedDailyInOut?.cont_no) {
      setInputCategory('container');
    }
    setValue('in_out_type', inOut);
    setIsOut(!!inOut?.includes('DTO'));
    setSelectedYard(selectedDailyInOut?.yard);
    setIsBare(!!selectedDailyInOut?.bare);
    setExistingData(selectedDailyInOut);
  };

  const clearAllFields = () => {
    setExistingData({});
    setInputCategory('');
    setIsBare(false);
    setSelectedYard(null);
    setIsOut(false);
    reset({
      created_date: '',
      created_time: '',
      created_by: '',
      updated_date: '',
      updated_time: '',
      updated_by: '',
      yard: '',
      wo_div: '',
      category: '',
      driver: '',
      in_out_type: 'DTI001',
      remarks: '',
      cont_no: '',
      cont_size: '',
      cont_type: '',
      cont_ssl: '',
      cont_seal: '',
      cont_color: '',
      cont_yard_location: '',
      cont_remarks: '',
      trailer_no: '',
      trailer_size: '',
      trailer_type: '',
      trailer_ssl: '',
      trailer_seal: '',
      trailer_color: '',
      trailer_yard_location: '',
      trailer_remarks: '',
      truck_no: '',
      truck_type: '',
      truck_ssl: '',
      truck_seal: '',
      truck_color: '',
      truck_yard_location: '',
      truck_remarks: '',
      ch_no: '',
      ch_pool: '',
      ch_type: '',
      ch_plate_no: '',
      ch_state: '',
      ch_bare: false,
      ch_color: '',
      ch_yard_location: '',
      ch_remarks: '',
    });
  };

  return (
    <CommonMessageModalFrame
      isOpen={isAddEditModalOpen}
      onCloseButtonClick={() => {
        clearAllFields();
        setIsAddEditModalOpen(false);
      }}
      header={isAdd ? 'Add Daily In/Out' : 'Edit Daily In/Out'}
      content={
        <FormProvider {...addEditModalController}>
          <form>
            <div>
              <BasicInfo
                selectedDailyInOut={selectedDailyInOut}
                isAdd={isAdd}
                divList={divList}
                yardOption={yardOption}
                driverOption={driverOption}
                isOut={isOut}
                setIsOut={setIsOut}
                div={div}
                selectedYard={selectedYard}
                setSelectedYard={setSelectedYard}
                inputCategory={inputCategory}
                setInputCategory={setInputCategory}
                existingData={existingData}
                hasEditablePermission={hasEditablePermission}
              />
              {(isAdd || selectedDailyInOut.cont_no) && (
                <Container
                  selectedDailyInOut={selectedDailyInOut}
                  isAdd={isAdd}
                  isOut={isOut}
                  inventoryContainerList={inventoryContainerList}
                  sizeOption={sizeOption}
                  typeOption={typeOption}
                  sslOption={sslOption}
                  inputCategory={inputCategory}
                  setInputCategory={setInputCategory}
                  isBare={isBare}
                  selectedYard={selectedYard}
                  isLoadingInventoryContainerList={
                    isLoadingInventoryContainerList
                  }
                  existingData={existingData}
                />
              )}
              {(isAdd || selectedDailyInOut.ch_no) && (
                <Chassis
                  selectedDailyInOut={selectedDailyInOut}
                  isAdd={isAdd}
                  isOut={isOut}
                  inventoryChassisList={inventoryChassisList}
                  poolOption={poolOption}
                  inputCategory={inputCategory}
                  setInputCategory={setInputCategory}
                  setIsBare={setIsBare}
                  selectedYard={selectedYard}
                  chassisTypeOption={chassisTypeOption}
                  isLoadingInventoryChassisList={isLoadingInventoryChassisList}
                  existingData={existingData}
                />
              )}
              {(isAdd || selectedDailyInOut.trailer_no) && (
                <Trailer
                  selectedDailyInOut={selectedDailyInOut}
                  isAdd={isAdd}
                  isOut={isOut}
                  inventoryTrailerList={inventoryTrailerList}
                  sizeOption={sizeOption}
                  typeOption={typeOption}
                  inputCategory={inputCategory}
                  setInputCategory={setInputCategory}
                  selectedYard={selectedYard}
                  isBare={isBare}
                  isLoadingInventoryTrailerList={isLoadingInventoryTrailerList}
                  existingData={existingData}
                />
              )}
              {(isAdd || selectedDailyInOut.truck_no) && (
                <Truck
                  selectedDailyInOut={selectedDailyInOut}
                  isAdd={isAdd}
                  isOut={isOut}
                  inventoryTruckList={inventoryTruckList}
                  selectedYard={selectedYard}
                  isLoadingInventoryTruckList={isLoadingInventoryTruckList}
                  existingData={existingData}
                />
              )}
            </div>
          </form>
        </FormProvider>
      }
      button={
        isAdd ? (
          <div className="flex justify-center space-x-[18px]">
            <div>
              <CommonButton
                icon={PATH_COMMON_BUTTON_ICON.clearRed}
                styles={PRESET_RED}
                onClick={clearAllFields}
              >
                Clear
              </CommonButton>
            </div>
            <div>
              <CommonButton
                type="submit"
                icon={PATH_COMMON_BUTTON_ICON.saveBlue}
                styles={PRESET_PRIMARY}
                preventDupClick
                onClick={({ buttonRef }) => {
                  if (!isValid && buttonRef)
                    updateButtonDisabled({ ref: buttonRef, disabled: false });
                  addEditModalController.handleSubmit(data => {
                    addDailyInOut({ data, buttonRef });
                  })();
                }}
              >
                Save
              </CommonButton>
            </div>
          </div>
        ) : (
          <div className="flex justify-between w-full">
            <div className="flex-grow" />
            <div
              className={`flex justify-center space-x-[18px] ${isOutEditModal ? 'ml-[46px]' : 'ml-[37px]'}`}
            >
              <div>
                <CommonButton
                  icon={PATH_COMMON_BUTTON_ICON.cancelRed}
                  styles={PRESET_RED}
                  onClick={() => {
                    setIsAddEditModalOpen(false);
                  }}
                >
                  Close
                </CommonButton>
              </div>
              {hasEditablePermission && (
                <div>
                  <CommonButton
                    type="submit"
                    icon={PATH_COMMON_BUTTON_ICON.saveBlue}
                    styles={PRESET_PRIMARY}
                    preventDupClick
                    onClick={({ buttonRef }) => {
                      if (!isValid && buttonRef)
                        updateButtonDisabled({
                          ref: buttonRef,
                          disabled: false,
                        });
                      addEditModalController.handleSubmit(submitData =>
                        editDailyInOut({ submitData, buttonRef }),
                      )();
                    }}
                  >
                    Save
                  </CommonButton>
                </div>
              )}
            </div>
            <div className="flex-grow flex justify-end mr-[10px]">
              {hasEditablePermission && isOutEditModal && (
                <CommonButton
                  icon={PATH_COMMON_BUTTON_ICON.inOrange}
                  styles={PRESET_ORANGE}
                  onClick={changeInOut}
                >
                  In
                </CommonButton>
              )}
              {hasEditablePermission && !isOutEditModal && (
                <CommonButton
                  icon={PATH_COMMON_BUTTON_ICON.outGreen}
                  styles={PRESET_GREEN}
                  onClick={changeInOut}
                >
                  Out
                </CommonButton>
              )}
            </div>
          </div>
        )
      }
    />
  );
};

export default AddEditModal;
