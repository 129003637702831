import dayjs from 'dayjs';
import {
  convertMRCategory,
  convertMRSubCategory,
  convertMRType,
} from '../Handler/typeCategoryConverter';
import FloatingFilterSelect from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import FloatingFilterInput from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const createMRColumnDefs = ({ ...props }) => {
  /** Filter item */
  const { filterDivisionList } = props;

  return [
    {
      headerName: 'DIV',
      field: 'div',
      editable: false,
      width: 38,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: filterDivisionList,
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'EQ ID',
      field: 'equip_id',
      editable: false,
      width: 77,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'MAKE YEAR',
      field: 'make_year',
      editable: false,
      width: 90,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'TYPE',
      field: 'type',
      editable: false,
      width: 57,
      valueFormatter: params => {
        return convertMRType(params?.value);
      },
      valueGetter: params => {
        return convertMRType(params?.data?.[params?.colDef?.field]);
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'CAT',
      field: 'category',
      editable: false,
      width: 90,
      valueFormatter: params => {
        return convertMRCategory(params?.value);
      },
      valueGetter: params => {
        return convertMRCategory(params?.data?.[params?.colDef?.field]);
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'SUB CAT',
      field: 'sub_category',
      editable: false,
      width: 96,
      valueFormatter: params => {
        const data = params?.data;
        return convertMRSubCategory({
          type: data?.type,
          category: data?.category,
          subCategory: params?.value,
        });
      },
      valueGetter: params => {
        const data = params?.data;
        return convertMRSubCategory({
          type: data?.type,
          category: data?.category,
          subCategory: data?.[params?.colDef?.field],
        });
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'ODOMETER',
      field: 'odometer',
      editable: false,
      width: 90,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'REPAIRED BY',
      field: 'repaired_by',
      editable: false,
      width: 97,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'INV #',
      field: 'inv_no',
      editable: false,
      width: 100,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'DATE',
      field: 'repaired_date',
      editable: false,
      width: 62,
      cellClass: 'date',
      comparator: dateComparator,
      valueFormatter: params => {
        const data = params?.data?.repaired_date;
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      valueGetter: params => {
        const data = params?.data?.repaired_date;
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'LABOR $',
      field: 'labor',
      editable: false,
      width: 72,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'right',
          },
        }),
    },
    {
      headerName: 'PART $',
      field: 'part',
      editable: false,
      width: 72,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'right',
          },
        }),
    },
    {
      headerName: 'OTHER $',
      field: 'other',
      editable: false,
      width: 72,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'right',
          },
        }),
    },
    {
      headerName: 'TAX $',
      field: 'tax',
      editable: false,
      width: 72,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'right',
          },
        }),
    },
    {
      headerName: 'TOTAL $',
      field: 'total',
      editable: false,
      width: 72,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'right',
          },
        }),
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      editable: false,
    },
  ];
};

export default createMRColumnDefs;
