import React, { useEffect, useState } from 'react';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import { removeImageFileList } from '../../../../util/Safety/DamageReport/Handler/photoFileHandler';

const PhotoBox = ({
  index,
  setImageFileList,
  file,
  isCompleted,
  isEditable,
}) => {
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    if (file?.lastModified) {
      setImageURL(URL.createObjectURL(file));
    } else {
      setImageURL(file?.file_url);
    }
  }, [file]);

  return (
    <div>
      <div className="flex flex-col items-center justify-center w-[60px] h-[60px] bg-[#F2F6FF] rounded-[5px]">
        {imageURL ? (
          <div
            className="w-full h-full object-cover rounded-[5px]"
            onClick={() => window.open(imageURL, '_blank')}
          >
            <img src={imageURL} alt="damage" />
          </div>
        ) : (
          <span className="font-[500] text-[12px] text-[#556DA1] mt-[9px]">
            Photo
          </span>
        )}
      </div>
      <div className="flex flex-row items-center w-[60px] h-[13px] max-h-[13px] pr-[5px] gap-x-[6px] mt-[3px] justify-between">
        <div className="flex items-center w-[50px] h-[13px] font-[300] text-[11px] text-[#595656] text-nowrap overflow-x-hidden overflow-y-hidden">
          {file?.name || file?.file_name}
        </div>
        {!isCompleted && isEditable && (
          <div
            className="w-[7px] h-[7px] pt-[3px]  hover:cursor-pointer"
            onClick={() =>
              removeImageFileList({
                setImageFileList,
                removeIdx: index,
              })
            }
          >
            <img
              src={PATH_COMMON_BUTTON_ICON?.crossRed?.default}
              alt="cancel"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoBox;
