import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import dailyInOutDefaultAPI from '../api';

const getInventoryChassisWithContainerList = async ({ div, yard }) => {
  try {
    const response = await dailyInOutDefaultAPI.get(
      'invt-chassis-with-container',
      {
        params: { div, yard },
      },
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getInventoryChassisWithContainerList;
