import FloatingFilterSelect from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { CELL_TYPE_SWITCH } from '../../../../constant/Common/DataGrid/cellType';
import { SWITCH_OPTION_ACTIVE } from '../../../../constant/Common/Option/Switch';
import {
  CARRIER_OPTION,
  STATUS_OPTION,
} from '../../../../constant/Safety/ITTablet';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const createSafetyITTabletColumnDefs = ({
  patchITTabletActiveMutation,
  isEditable,
  disabledRef,
}) => {
  const colDefs = [
    {
      headerName: 'TABLET ID',
      field: 'tablet_id',
      width: 80,
      editable: false,
    },
    {
      headerName: 'MAKE',
      field: 'make',
      width: 100,
      editable: false,
    },
    {
      headerName: 'MODEL',
      field: 'model',
      width: 180,
      editable: false,
    },
    {
      headerName: 'DIV',
      field: 'div',
      width: 38,
      editable: false,
    },
    {
      headerName: 'DRIVER',
      field: 'driver',
      width: 72,
      editable: false,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      width: 80,
      editable: false,
      valueGetter: params => {
        const status = STATUS_OPTION.find(
          option => option.value === params?.data?.status,
        );
        return status?.label;
      },
    },
    {
      headerName: 'IMEI',
      field: 'imei',
      width: 120,
      editable: false,
    },
    {
      headerName: 'CARRIER',
      field: 'carrier',
      width: 80,
      editable: false,
      valueGetter: params => {
        const carrier = CARRIER_OPTION.find(
          option => option.value === params?.data?.carrier,
        );
        return carrier?.label;
      },
    },
    {
      headerName: 'SIM',
      field: 'sim_no',
      width: 150,
      editable: false,
    },
    {
      headerName: 'CELL #',
      field: 'cell_no',
      width: 95,
      editable: false,
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      editable: false,
    },
  ];
  if (isEditable) {
    colDefs.push({
      headerName: 'ACTIVE',
      field: 'active',
      width: 75,
      editable: false,
      clickable: true,
      valueGetter: params => {
        return params?.data?.active ? 'Active' : 'Inactive';
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          value: gridParam?.data?.active,
          onChangeHandler: async ({ ...params }) => {
            if (
              disabledRef?.current?.disabled &&
              disabledRef?.current?.disabledRow === gridParam?.rowIndex
            ) {
              return;
            }
            disabledRef.current.disabled = true;
            disabledRef.current.disabledRow = gridParam?.rowIndex;

            const { e, setChecked } = params;

            setChecked(!!e?.target?.checked);
            await patchITTabletActiveMutation({
              seq: gridParam.data.seq,
              setChecked,
            });
          },
        }),
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: SWITCH_OPTION_ACTIVE,
      },
    });
  }
  return colDefs;
};

export default createSafetyITTabletColumnDefs;
