const clickGridCell = ({ setSpecificSeq, setModalOpen, ...props }) => {
  const inventoryPage = document?.URL?.split('/')?.pop();

  if (
    inventoryPage !== 'linked-equips' &&
    props?.gridParam?.column?.colId !== 'remarks'
  ) {
    setSpecificSeq?.(props?.gridParam?.data?.seq);
    setModalOpen(true);
  }
};

export default clickGridCell;
