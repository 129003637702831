import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import dailyInOutDefaultAPI from '../api';

const postFlip = async ({ data }) => {
  try {
    const response = await dailyInOutDefaultAPI.post(`chassis-flip`, {
      div: data?.div,
      yard: data?.yard,
      remarks: data?.remarks,
      cont_no_1: data?.cont_no_1,
      cont_no_2: data?.cont_no_2,
      cont_remarks_1: data?.cont_remarks_1,
      cont_remarks_2: data?.cont_remarks_2,
      ch_no_1: data?.ch_no_1,
      ch_no_2: data?.ch_no_2,
      ch_remarks_1: data?.ch_remarks_1,
      ch_remarks_2: data?.ch_remarks_2,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postFlip;
