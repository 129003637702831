import React, { forwardRef } from 'react';
import CommonButton from '../../Controller/Button';
import FilterContent from './Content';
import StyledFilterModalWrapper from '../../../../style/Common/Filter/Modal/StyledFilterModalWrapper';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import { PRESET_PRIMARY } from '../../../../constant/Common/Style/preset';

const FilterModal = forwardRef((props, ref) => {
  const { filters, onSave } = props;

  return (
    <StyledFilterModalWrapper ref={ref}>
      {/* Content area */}
      {(filters === undefined || filters?.length === 0) && (
        <div className="text-[14px] text-[#666666] w-[150px] p-[10px] flex justify-center items-center">
          No filter available
        </div>
      )}
      {filters && filters?.length > 0 && (
        <div className="p-[3px]">
          {filters?.map((filter, index) => (
            <FilterContent key={String(index + 1)} {...filter} />
          ))}
        </div>
      )}
      {/* Button area */}
      <div className="w-full flex justify-center items-center p-[10px]">
        <CommonButton
          icon={PATH_COMMON_BUTTON_ICON.saveBlue}
          styles={PRESET_PRIMARY}
          onClick={onSave}
        >
          Save
        </CommonButton>
      </div>
    </StyledFilterModalWrapper>
  );
});

export default FilterModal;
