export const STYLE_EACH_ROW_WRAPPER =
  'w-full h-fit flex items-center flex-row gap-[25px]';
export const STYLE_BASIC_INFO_CONTENT_WRAPPER =
  'w-fit h-full flex justify-center items-center flex-row gap-[15px]';
export const STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER =
  'w-fit h-full flex justify-center items-center flex-row gap-[10px]';
export const STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_2PX =
  'w-fit h-full flex justify-center items-center flex-row gap-[2px]';
export const STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_6PX =
  'w-fit h-full flex justify-center items-center flex-row gap-[6px]';
export const STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_8PX =
  'w-fit h-full flex justify-center items-center flex-row gap-[8px]';
export const STYLE_BASIC_INFO_RELATED_CONTENT_WRAPPER_16PX =
  'w-fit h-full flex justify-center items-center flex-row gap-[16px]';
export const STYLE_CONTENT_WRAPPER =
  'w-fit h-full flex justify-center items-center flex-row gap-[13px]';
export const STYLE_ITEM_WRAPPER = 'flex justify-center items-center flex-col';
