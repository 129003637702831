import React from 'react';
import FilterItem from './Item';
import StyledFilterContentWrapper from '../../../../../style/Common/Filter/Modal/Content/StyledFilterContentWrapper';

const FilterContent = ({ ...props }) => {
  const { title, items } = props;

  return (
    <StyledFilterContentWrapper>
      <div className="flex flex-row gap-1">
        <div>
          <img
            alt="Filter Content Theme Icon"
            width={9}
            height={9}
            src={title?.icon}
          />
        </div>
        <div
          className="flex justify-center items-center
                      text-xs text-[#666] font-medium leading-[10px]"
        >
          {title?.label || '-'}
        </div>
      </div>
      {/* Filter items */}
      <div className="flex flex-wrap gap-[8px]">
        {items?.map((item, index) => (
          <FilterItem key={String(index + 1)} {...item} />
        ))}
      </div>
    </StyledFilterContentWrapper>
  );
};

export default FilterContent;
