import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import StyledCommonInputRequiredLabel from '../../../../style/Common/Controller/Common/StyledCommonInputRequiredLabel';
import StyledCommonInputWrapper from '../../../../style/Common/Controller/Common/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../../style/Common/Controller/Common/StyledCommonLabel';
import createDefaultInputRules from '../../../../util/Common/Controller/Generator/createDefaultInputRules';

const CommonRadioInput = ({ ...props }) => {
  const {
    control,
    unregister,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();
  /**
   * @label {string} - Label of input
   * @maxLength {number} - Max length of input
   * @required {boolean} - Required input
   * @disabled {boolean} - Disable input
   * @defaultValue {string} - Default value of input
   * @width {string} - Width of input
   * @inputName {string} - Name of input
   */
  const [label, setLabel] = useState(props?.label);

  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    setRequired(props?.required);
  }, [props?.required]);

  const [disabled, setDisabled] = useState(
    props?.disabled || [false, false, false, false],
  );
  useEffect(() => {
    setDisabled(props?.disabled);
  }, [props?.disabled]);

  /**
   * Options include items that go into the radio input.
   * Options include label and disabled.
   * @Label name of each options in radio input.
   * @Disabled when status is true users can't choose this.
   */

  const [options, setOptions] = useState(
    props?.options || [
      { label: 'radio1', disabled: false, value: 1 },
      { label: 'radio2', disabled: false, value: 2 },
      { label: 'radio3', disabled: false, value: 3 },
      { label: 'radio4', disabled: false, value: 4 },
    ],
  );
  useEffect(() => {
    setOptions(props?.options);
  }, [props?.options]);

  const [defaultValue, setDefaultValue] = useState(
    props?.defaultValue || options[0]?.label,
  );
  useEffect(() => {
    setDefaultValue(props?.defaultValue);
  }, [props?.defaultValue]);

  const [inputName, setInputName] = useState(props?.inputName || 'radioGroup');
  useEffect(() => {
    if (props?.inputName !== undefined && props?.inputName !== null)
      setInputName(props?.inputName);
  }, [props?.inputName]);

  return (
    <>
      <div>
        {(!!label || props.labelVisible !== false) && (
          <StyledCommonLabel
            labelStyle={props?.labelStyle}
            lbl-pd-right={props?.[`lbl-pd-right`]}
          >
            {!!label && (
              <>
                {required && (
                  <StyledCommonInputRequiredLabel>
                    *
                  </StyledCommonInputRequiredLabel>
                )}
                {label}
              </>
            )}
          </StyledCommonLabel>
        )}
      </div>
      <div>
        <div>
          <Controller
            control={control}
            name={inputName}
            defaultValue={defaultValue}
            rules={createDefaultInputRules({ ...props })}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { invalid, error },
            }) => {
              // Package of handler props
              const handlerProps = {
                onChange,
                value,
                ...field,
                ...props,
              };

              // Render Radio Input Component
              return (
                <div>
                  <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
                    <div
                      className={`${
                        props?.width ? `${props?.width}` : 'w-full'
                      } group  
                    py-[9px] text-[12px] flex items-center justify-evenly w-full
                    ${
                      formErrors?.[inputName]?.message
                        ? 'border-red-600 hover'
                        : ''
                    } ${
                      props?.borderVisible
                        ? 'border-[1px] rounded-[5px] hover:border-[#8E9396] active:border-[#264B9F] active:shadow-[0_0_4px_0_#8BBCE9] border-[#D9D9D9]'
                        : ''
                    } ${props?.styles?.gap}
                    `}
                    >
                      {options?.map((res, index) => {
                        return (
                          <div
                            key={res.value}
                            className="flex items-center gap-x-[6px] group-focus:shadow-[0_0_4px_0_#8BBCE9]"
                          >
                            <input
                              className="group-focus:shadow-[0_0_4px_0_#8BBCE9] checked:border-[#264B9F] checked:bg-[#264B9F] cursor-pointer checked:border-#264B9F checked:bg-#264B9F "
                              disabled={disabled?.[index]}
                              type="radio"
                              value={res?.value}
                              checked={res?.value === value}
                              id={`${inputName}-${res?.label}`}
                              name={inputName}
                              onChange={e => {
                                props?.onChangeHandler?.({
                                  e,
                                  onChange,
                                  ...handlerProps,
                                });
                              }}
                            />
                            <label
                              className="cursor-pointer"
                              htmlFor={`${inputName}-${res?.label}`}
                            >
                              {res?.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </StyledCommonInputWrapper>
                </div>
              );
            }}
          />
        </div>
      </div>
      <div
        className={`${
          formErrors?.[inputName]?.message ? 'block' : 'invisible'
        }`}
      >
        <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
          {!props?.suppressErrorVisible && formErrors?.[inputName]?.message}
        </FormHelperText>
      </div>
    </>
  );
};

export default CommonRadioInput;
