import React from 'react';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';
import CommonButton from '../../Common/Controller/Button';

const FlipButton = ({ setIsFlipModalOpen }) => {
  return (
    <CommonButton
      icon={PATH_COMMON_BUTTON_ICON.flipBlue}
      styles={{
        border: '1px solid #556DA1',
        backgroundColor: '#fff',
        hoverBackgroundColor: '#556DA1',
        hoverBorder: '1px solid #556DA1',
        hoverColor: '#fff',
        color: '#556DA1',
      }}
      onClick={() => {
        setIsFlipModalOpen(true);
      }}
    >
      Flip
    </CommonButton>
  );
};

export default FlipButton;
