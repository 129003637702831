import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorAccessDenied from '../../../component/Common/Error/ErrorAccessDenied';
import clearLocalStorage from '../../../util/Common/Auth/Handler/clearLocalStorage';

const AccessDenied = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      clearLocalStorage();
      sessionStorage.clear();
      navigate('/sign-in');
    }, 6000);
  }, []);

  return <ErrorAccessDenied />;
};

export default AccessDenied;
