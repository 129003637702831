import createTruckColumnDefs from '../../../util/Inventory/Truck/Generator/createTruckColumnDefs';
import createContainerColumnDefs from '../../../util/Inventory/Container/Generator/createContainerColumnDefs';
import createChassisColumnDefs from '../../../util/Inventory/Chassis/Generator/createChassisColumnDefs';
import createTrailerColumnDefs from '../../../util/Inventory/Trailer/Generator/createTrailerColumnDefs';
import { SIDEBAR_SUB_MENU } from '../../Common/Option/Sidebar';
import createLinkedEquipsColumnDefs from '../../../util/Inventory/ContainerChassis/Generator/createLinkedEquipsColumnDefs';

export const INVENTORY_FILTER_NAVIGATOR_VALUE = [
  {
    header: 'TRUCK',
    value: 'truck',
    count: 'trucks',
    to: SIDEBAR_SUB_MENU?.[1]?.[0]?.to,
  },
  {
    header: 'TRAILER',
    value: 'trailer',
    count: 'trailers',
    to: SIDEBAR_SUB_MENU?.[1]?.[1]?.to,
  },
  {
    header: 'CONT',
    value: 'container',
    count: 'containers',
    to: SIDEBAR_SUB_MENU?.[1]?.[2]?.to,
  },
  {
    header: 'CHASSIS',
    value: 'chassis',
    count: 'chassis',
    to: SIDEBAR_SUB_MENU?.[1]?.[3]?.to,
  },
  {
    header: 'CONT + CHASSIS',
    value: 'linked-equips',
    count: 'linked',
    to: SIDEBAR_SUB_MENU?.[1]?.[4]?.to,
  },
];

export const INVENTORY_KEY_VALUE = {
  truck: 'TRUCK',
  container: 'CONTAINER',
  chassis: 'CHASSIS',
  trailer: 'TRAILER',
  'linked-equips': 'LINKED-EQUIPS',
};

export const INVENTORY_EDIT_HEADER = {
  truck: 'Truck',
  container: 'Container',
  chassis: 'Chassis',
  trailer: 'Trailer',
  'linked-equips': 'Container + Chassis',
};

export const INVENTORY_COLUMN_DEFINITIONS = {
  truck: createTruckColumnDefs,
  container: createContainerColumnDefs,
  chassis: createChassisColumnDefs,
  trailer: createTrailerColumnDefs,
  'linked-equips': createLinkedEquipsColumnDefs,
};
