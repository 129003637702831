import FloatingFilterSelect from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { CELL_TYPE_SWITCH } from '../../../../constant/Common/DataGrid/cellType';
import { BASIC_INFO_STATUS } from '../../../../constant/Common/Option/Status';
import { SWITCH_OPTION_ACTIVE } from '../../../../constant/Common/Option/Switch';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const createTruckColumnDefs = ({ mutatePatchActive, ...props }) => {
  /** Filter item */
  const { filterDivisionList, isEditable, disabledRef } = props;

  const safetyTruckColumn = [
    {
      headerName: 'DIV',
      field: 'div',
      editable: false,
      width: 38,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: filterDivisionList,
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'STATUS',
      field: 'status',
      editable: false,
      width: 70,
      valueFormatter: params => {
        return BASIC_INFO_STATUS.find(
          element => element?.value === params?.value,
        )?.label;
      },
      valueGetter: params => {
        return BASIC_INFO_STATUS.find(
          element => element?.value === params?.data?.status,
        )?.label;
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'OPERATED BY',
      field: 'driver',
      editable: false,
      width: 103,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'TRUCK #',
      field: 'truck_no',
      editable: false,
      width: 69,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'YEAR',
      field: 'make_year',
      editable: false,
      width: 50,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'MAKE',
      field: 'truck_make',
      editable: false,
      width: 100,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'MODEL',
      field: 'truck_model',
      editable: false,
      width: 100,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'VIN #',
      field: 'vin_no',
      editable: false,
      width: 160,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'PLATE #',
      field: 'plate_no',
      editable: false,
      width: 90,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      editable: false,
    },
  ];

  if (isEditable)
    safetyTruckColumn?.push({
      headerName: 'ACTIVE',
      field: 'active',
      editable: false,
      clickable: true,
      width: 75,
      valueFormatter: params => params.value,
      valueGetter: params => {
        return params?.data?.active ? 'Active' : 'Inactive';
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          value: gridParam?.data?.active,
          onChangeHandler: ({ ...params }) => {
            if (
              disabledRef?.current?.disabled &&
              disabledRef?.current?.disabledRow === gridParam?.rowIndex
            ) {
              return;
            }
            disabledRef.current.disabled = true;
            disabledRef.current.disabledRow = gridParam?.rowIndex;

            const { e, setChecked } = params;
            const { data } = gridParam;

            setChecked(!!e?.target?.checked);
            mutatePatchActive?.({ seq: data?.seq, setChecked });
          },
        }),
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: SWITCH_OPTION_ACTIVE,
      },
    });

  return safetyTruckColumn;
};

export default createTruckColumnDefs;
