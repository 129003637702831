import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';
import {
  PRESET_PRIMARY,
  PRESET_RED,
} from '../../../constant/Common/Style/preset';
import useGetInventoryChassisWithContainerList from '../../../hook/DailyInOut/Get/useGetInventoryChassisWithContainerList';
import useGetInventoryContainerWithChassisList from '../../../hook/DailyInOut/Get/useGetInventoryContainerWithChassisList';
import usePostFlipMutation from '../../../hook/DailyInOut/Post/usePostFlipMutation';
import updateButtonDisabled from '../../../util/Common/Controller/Handler/updateButtonDisabled';
import { createOptionalSelectOption } from '../../../util/Common/Option/Generator/selectOptionCreator';
import CommonButton from '../../Common/Controller/Button';
import CommonMessageModalFrame from '../../Common/Modal/Message/Frame';
import BasicInfo from './BasicInfo';
import Set1 from './Set1';
import Set2 from './Set2';

const FlipModal = ({
  isFlipModalOpen,
  setIsFlipModalOpen,
  yardOption,
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  fromDate,
  toDate,
  yard,
  equipment,
  equipmentType,
}) => {
  const flipModalController = useForm();
  const currDiv = sessionStorage.getItem('currDiv');
  const [selectedYard, setSelectedYard] = useState(null);
  const [
    inventoryContainerWithChassisOption,
    setInventoryContainerWithChassisOption,
  ] = useState([]);
  const [
    inventoryChassisWithContainerOption,
    setInventoryChassisWithContainerOption,
  ] = useState([]);

  useEffect(() => {
    flipModalController.reset({
      yard: selectedYard,
    });
  }, [selectedYard]);

  const { inventoryContainerWithChassisList } =
    useGetInventoryContainerWithChassisList({
      div: currDiv,
      yard: selectedYard,
    });

  const { inventoryChassisWithContainerList } =
    useGetInventoryChassisWithContainerList({
      div: currDiv,
      yard: selectedYard,
    });

  const { mutate: postFlipMutation } = usePostFlipMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
    setIsFlipModalOpen,
    setSelectedYard,
    div: currDiv,
    fromDate,
    toDate,
    yard,
    equipmentType,
    equipment,
  });

  const addFlip = ({ data, buttonRef }) => {
    postFlipMutation({
      data,
      buttonRef,
    });
  };

  useEffect(() => {
    setInventoryContainerWithChassisOption(
      createOptionalSelectOption({
        items: inventoryContainerWithChassisList,
        labelKey: 'cont_no',
        valueKey: 'cont_no',
      }),
    );
  }, [inventoryContainerWithChassisList]);

  useEffect(() => {
    setInventoryChassisWithContainerOption(
      createOptionalSelectOption({
        items: inventoryChassisWithContainerList,
        labelKey: 'ch_no',
        valueKey: 'ch_no',
      }),
    );
  }, [inventoryChassisWithContainerList]);

  return (
    <CommonMessageModalFrame
      isOpen={isFlipModalOpen}
      onCloseButtonClick={() => {
        setIsFlipModalOpen(false);
        setSelectedYard(null);
        flipModalController.reset();
      }}
      header="Flip"
      content={
        <FormProvider {...flipModalController}>
          <form>
            <BasicInfo
              yardOption={yardOption}
              currDiv={currDiv}
              selectedYard={selectedYard}
              setSelectedYard={setSelectedYard}
            />
            <div className="flex mt-[16px] gap-[10px]">
              <Set1
                selectedYard={selectedYard}
                flipModalController={flipModalController}
                inventoryContainerWithChassisOption={
                  inventoryContainerWithChassisOption
                }
                inventoryContainerWithChassisList={
                  inventoryContainerWithChassisList
                }
                inventoryChassisWithContainerOption={
                  inventoryChassisWithContainerOption
                }
                inventoryChassisWithContainerList={
                  inventoryChassisWithContainerList
                }
              />
              <Set2
                selectedYard={selectedYard}
                flipModalController={flipModalController}
                inventoryContainerWithChassisOption={
                  inventoryContainerWithChassisOption
                }
                inventoryContainerWithChassisList={
                  inventoryContainerWithChassisList
                }
                inventoryChassisWithContainerOption={
                  inventoryChassisWithContainerOption
                }
                inventoryChassisWithContainerList={
                  inventoryChassisWithContainerList
                }
              />
            </div>
          </form>
        </FormProvider>
      }
      button={
        <div className="flex justify-center space-x-[18px]">
          <div>
            <CommonButton
              icon={PATH_COMMON_BUTTON_ICON.clearRed}
              styles={PRESET_RED}
              onClick={() => {
                setSelectedYard(null);
                flipModalController.reset({
                  yard: null,
                });
              }}
            >
              Clear
            </CommonButton>
          </div>
          <div>
            <CommonButton
              type="submit"
              icon={PATH_COMMON_BUTTON_ICON.flipBlue}
              styles={PRESET_PRIMARY}
              preventDupClick
              onClick={({ buttonRef }) => {
                if (buttonRef)
                  updateButtonDisabled({ ref: buttonRef, disabled: false });
                flipModalController.handleSubmit(data => {
                  addFlip({
                    data,
                    buttonRef,
                  });
                })();
              }}
            >
              Flip
            </CommonButton>
          </div>
        </div>
      }
    />
  );
};

export default FlipModal;
