import React, { forwardRef } from 'react';
import CommonButton from '../../Controller/Button';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';

const FilterButton = forwardRef(({ ...props }, ref) => {
  const { setOpen } = props;

  return (
    <CommonButton
      ref={ref}
      icon={PATH_COMMON_BUTTON_ICON.filter}
      styles={{
        hoverBackgroundColor: '#666666',
        hoverColor: '#fff',
        color: '#666666',
      }}
      onClick={() => setOpen?.(prev => !prev)}
    />
  );
});

export default FilterButton;
