import { useEffect, useRef, useState } from 'react';

const useControlCommonFilterOpen = ({ ...props }) => {
  const { open, setOpen } = props;

  /** Reference to control component */
  const buttonRef = useRef(null);
  const modalRef = useRef(null);

  /** Listener */
  const handleClickOutside = event => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpen?.(prev => false);
    }
  };

  /** Event listener handler */
  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [open]);

  return { buttonRef, modalRef, open, setOpen };
};

export default useControlCommonFilterOpen;
