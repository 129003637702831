import React from 'react';
import CommonDataGrid from '../../Common/Datagrid';
import StyledInventoryDatagridWrapper from '../../../style/Inventory/StyledInventoryDatagridWrapper';
import useControlTotalInfo from '../../../hook/Common/TotalBottomBar/useControlTotalInfo';
import TotalBottomBar from '../../Common/TotalBottomBar';
import clickGridCell from '../../../util/Inventory/Common/Handler/clickGridCell';

const InventoryDatagrid = ({ ...props }) => {
  const { gridRef, columnDefs, data, setSpecificSeq, setModalOpen } = props;

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: data,
  });

  return (
    <StyledInventoryDatagridWrapper>
      <CommonDataGrid
        gridRef={gridRef || null}
        columnDefs={columnDefs}
        data={data}
        onCellClicked={cell =>
          clickGridCell?.({ setSpecificSeq, setModalOpen, ...cell })
        }
        onFilterChanged={() => {
          setChangeFlag(prev => !prev);
        }}
        onSelectionChanged={() => {
          setChangeFlag(prev => !prev);
        }}
      />
      <TotalBottomBar totalRows={totalRows} />
    </StyledInventoryDatagridWrapper>
  );
};

export default InventoryDatagrid;
