import React, { useRef, useState } from 'react';
import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';
import UtilityBar from '../../Common/UtilityBar';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame';
import ModalHeader from '../../Common/Modal/ModalHeader';
import ModalButton from '../../Common/Modal/ModalButton';
import SafetyDatagrid from '../../Common/SafetyDatagrid';
import createVANColumnDefs from '../../../../util/Safety/VAN/Generator/createVANColumnDefs';
import ModalContent from '../Modal/ModalContent';
import useGetListData from '../../../../hook/Safety/VAN/Get/useGetListData';
import useGetSpecificVANData from '../../../../hook/Safety/VAN/Get/useGetSpecificVANData';
import usePatchActive from '../../../../hook/Safety/VAN/Patch/usePatchActive';
import usePatchSpecificVANData from '../../../../hook/Safety/VAN/Patch/usePatchSpecificVANData';
import usePostVANData from '../../../../hook/Safety/VAN/Post/usePostVANData';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import useFormController from '../../../../hook/Safety/VAN/Form/useFormController';
import useControlLoadingStatus from '../../../../hook/Safety/Common/Loading/useControlLoadingStatus';
import CommonLoading from '../../../Common/Loading';
import CommonAlert from '../../../Common/Modal/Alert';
import clickOKButton from '../../../../util/Safety/Common/Handler/Save/okButtonHandler';
import createModalDefaultValue from '../../../../util/Safety/VAN/Generator/createModalDefaultValue';
import saveVAN from '../../../../util/Safety/VAN/Handler/Save/saveVAN';
import useGetGridFilterOption from '../../../../hook/Safety/Common/Option/useGetGridFilterOption';
import checkPermission from '../../../../util/Common/Router/Handler/checkPermission';
import { PERM_SAFETY_VAN_EDIT } from '../../../../constant/Common/Permission';
import safetyVANKeys from '../../../../hook/Safety/VAN/keys';

const SafetyVANContents = () => {
  /** useRef for handling disabled */
  const disabledRef = useRef({ disabled: false, disabledRow: null });

  /** Essential */
  const gridRef = useRef(null);
  const saveButtonRef = useRef(null);
  const [seq, setSeq] = useState(null);
  const isEditable = () =>
    checkPermission({ permission: [PERM_SAFETY_VAN_EDIT] });

  /** Data modal */
  const [isModalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(EDIT_MODE?.add);

  /** Message modal opener */
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertModalStatus, setAlertModalStatus] = useState({
    message: '',
    status: ALERT_MODAL_STATUS?.STABLE,
  });
  const alertDataSetter = { setAlertOpen, setAlertModalStatus };

  /** Grid, Modal data */
  const {
    listData,
    isLoading: loadingGetList,
    isFetching: fetchingGetList,
  } = useGetListData({ ...alertDataSetter });
  const {
    specificData,
    isFetching: fetchingSpecificData,
    isLoading: loadingSpecificData,
    isRefetching: refetchingSpecificData,
    isError: errorSpecificData,
  } = useGetSpecificVANData({ seq, isModalOpen, ...alertDataSetter });

  /** Form */
  const { formController, formDefaultValue } = useFormController({
    isModalOpen,
    editMode,
    specificData,
  });

  /** Data patcher */
  const { mutate: mutatePatchActive, isPending: pendingPatchActive } =
    usePatchActive({
      gridRef,
      disabledRef,
      setAlertModalStatus,
      setAlertOpen,
    });
  const { mutate: editMutate, isPending: editMutatePending } =
    usePatchSpecificVANData({
      gridRef,
      setAlertModalStatus,
      setAlertOpen,
      setModalOpen,
    });
  const { mutate: addMutate, isPending: addMutatePending } = usePostVANData({
    setAlertModalStatus,
    setAlertOpen,
    setModalOpen,
  });

  /** Loading component opener */
  const { isLoadingOpen, setLoadingOpen } = useControlLoadingStatus({
    isFetching: [fetchingSpecificData],
    isLoading: [loadingGetList, loadingSpecificData],
    isPending: [],
  });

  /** Filter option */
  const gridFilterOption = useGetGridFilterOption();
  const { filterDivisionList } = gridFilterOption;

  /** Grid column definition */
  const [columnDefs, setColumnDefs] = useState(
    createVANColumnDefs({
      mutatePatchActive,
      isEditable: isEditable(),
      disabledRef,
      ...gridFilterOption,
    }),
  );

  return (
    <>
      <div className="pl-[29px] flex flex-col flex-1 pb-8">
        <UtilityBar
          setModalOpen={setModalOpen}
          setEditMode={setEditMode}
          gridRef={gridRef}
          isEditable={isEditable()}
          subPage="VAN"
          queryKeyList={[safetyVANKeys.listData()]}
        />
        {filterDivisionList?.length > 1 && (
          <SafetyDatagrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={listData}
            setSpecificSeq={setSeq}
            setModalOpen={setModalOpen}
            setEditMode={setEditMode}
            preventTargetColumnID={['active']}
          />
        )}
      </div>
      {isModalOpen && !loadingSpecificData && (
        <CommonMessageModalFrame
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          header={<ModalHeader editMode={editMode} title="VAN" />}
          content={
            <ModalContent
              formController={formController}
              data={formDefaultValue}
              isEditable={isEditable()}
            />
          }
          button={
            <ModalButton
              onClearClick={() => {
                formController?.reset({
                  ...createModalDefaultValue({
                    specificData,
                    editMode,
                  }),
                });
              }}
              onSaveClick={() => {
                formController?.handleSubmit(data =>
                  saveVAN?.({
                    data,
                    editMutate,
                    addMutate,
                    seq,
                    editMode,
                    saveButtonRef,
                  }),
                )();
              }}
              isEditable={isEditable()}
              saveButtonRef={saveButtonRef}
            />
          }
        />
      )}
      {/* Loading */}
      {isLoadingOpen && <CommonLoading isOpen={isLoadingOpen} />}
      {/* Alert */}
      {isAlertOpen &&
        alertModalStatus?.status !== ALERT_MODAL_STATUS?.SUCCESS && (
          <CommonAlert
            isOpen={isAlertOpen}
            setOpen={setAlertOpen}
            status={alertModalStatus?.status}
            content={alertModalStatus?.message}
            onOKButtonClick={() => {
              clickOKButton?.({
                setAlertOpen,
                setModalOpen,
                status: alertModalStatus?.status,
              });
            }}
          />
        )}
    </>
  );
};

export default SafetyVANContents;
