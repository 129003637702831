const DAILY_IN_OUT_TYPE = {
  DTI001: 'IN - EMPTY',
  DTI002: 'IN - LOAD',
  DTO001: 'OUT - EMPTY',
  DTO002: 'OUT - LOAD',
  DTF001: 'FLIP - EMPTY',
  DTF002: 'FLIP - LOAD',
};
const DAILY_IN_OUT_CATEGORY_TYPE = {
  DC0001: 'IMPORT',
  DC0002: 'EXPORT',
  DC0003: 'LOCAL',
};

const DAILY_IN_OUT_OPTION = [
  { label: 'Empty In', value: 'DTI001' },
  { label: 'Empty Out', value: 'DTO001' },
  { label: 'Load In', value: 'DTI002' },
  { label: 'Load Out', value: 'DTO002' },
];

const FLIP_OPTION = [
  { label: 'Empty', value: 'DTF001' },
  { label: 'Load', value: 'DTF002' },
];

const DAILY_IN_OUT_CATEGORY_OPTION = [
  { label: 'IMPORT', value: 'DC0001' },
  { label: 'EXPORT', value: 'DC0002' },
  { label: 'LOCAL', value: 'DC0003' },
];

const DAILY_IN_OUT_EQUIPMENT_TYPE = {
  Container: 'cont_no',
  Chassis: 'ch_no',
  Trailer: 'trailer_no',
  Truck: 'truck_no',
};

const DAILY_IN_OUT_EQUIPMENT_OPTION = [
  { label: 'Container', value: 'cont_no' },
  { label: 'Chassis', value: 'ch_no' },
  { label: 'Trailer', value: 'trailer_no' },
  { label: 'Truck', value: 'truck_no' },
];

export {
  DAILY_IN_OUT_CATEGORY_OPTION,
  DAILY_IN_OUT_CATEGORY_TYPE,
  DAILY_IN_OUT_EQUIPMENT_OPTION,
  DAILY_IN_OUT_EQUIPMENT_TYPE,
  DAILY_IN_OUT_OPTION,
  DAILY_IN_OUT_TYPE,
  FLIP_OPTION,
};
