import styled from 'styled-components';

const StyledFilterContentWrapper = styled.div`
  // Modal width = 305px
  // Left Right padding = 3px
  // 305px - (3px * 2) = 299px
  width: 299px;
  min-width: 299px;
  max-width: 299px;

  border-bottom: 0.5px solid rgba(212, 212, 212, 0.3);

  display: flex;
  flex-direction: column;

  // Gap between filter items title and contents
  gap: 8px;

  padding: 9px;
`;

export default StyledFilterContentWrapper;
