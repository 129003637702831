import COLUMN_ITEM_PAIR from '../../../../constant/Common/Error/column';
import ERROR_MESSAGE_PAIR from '../../../../constant/Common/Error/messagePair';

/**
 * @description
 * Function that generate specific error message according to error code.
 * @param {string} code
 * Error code from API
 * @returns {string}
 * Error message
 */
export const generateSpecificCodeErrorMessage = ({ error }) => {
  /** Get error from error response */
  const errors = error?.response?.data?.errors;
  const targetError = errors?.[0];

  /** Error property */
  const code = targetError?.code;
  const fields = targetError?.fields;
  const dependency = targetError?.dependency;
  const objects = targetError?.objects;

  /** Pair */
  const codePair = ERROR_MESSAGE_PAIR?.[code];
  const fieldPair = fields?.map(field => COLUMN_ITEM_PAIR?.[field]);
  const dependencyPair = dependency?.map(field => COLUMN_ITEM_PAIR?.[field]);

  /** Check specific code */
  if (code === 'dependent')
    return `${codePair} ${fieldPair?.join(', ')} and ${dependencyPair?.join(', ')}`;
  if (code === 'inventory_insert_failed')
    return `${fieldPair?.join(', ')} is already in the Inventory.`;
  if (code === 'inventory_delete_failed')
    return `${fieldPair?.join(', ')} doesn't exist in the Inventory.`;
  if (code === 'empty_fields') return `Please input at least one equipment.`;
  if (code === 'group_has_user') return `User is assigned. Please try again.`;
  if (code === 'in_out_daily_permission_missing')
    return `Daily In/Out permission is required.`;
  if (code === `yard_with_daily_in_out_deletion_failed`)
    return `You can not delete ${objects?.join(', ')}. Please contact to Admin.`;
  if (code === `yard_with_inventory_deactivation_failed`)
    return `Equipment exist in ${objects?.join(', ')}. Please try again after removing all equipment.`;
  if (code === 'empty_fields')
    return `All fields must have at least one non-empty value.`;
  if (code === 'username_creation_failed')
    return `Username creation failed. Please contact to admin.`;
  if (code === 'login_failed') return `Login failed. Please contact to admin.`;
  if (code === 'out_of_range')
    return `User creation failed. Please contact to admin.`;
  if (code === 'chassis_flip_failed')
    return `Chassis flip failed. Please check equipment again.`;

  /** Return code related message */
  const getCodeErrorMessage = ERROR_MESSAGE_PAIR?.[code];
  return getCodeErrorMessage;
};
